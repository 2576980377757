<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="invoices"
              :show-labels="false"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @async-search="onAsyncSearch"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <b-row v-show="dataTable.visible">
          <b-col>
            <invoices-table ref="invoices" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import InvoicesTable from "@/views/SalesAdministrative/InvoicesTable";

export default {
  name: "Invoices",
  components: {
    FilteringPanel,
    InvoicesTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            tooltip: "Account name",
            title: "Account Name",
            name: "account",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Responsible user name",
            title: "Assigned To",
            name: "assigned_to",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "daterange",
            dataType: "datetime",
            title: "Invoice Date",
            tooltip: "Invoice period",
            defaultRange: "Last 90 days",
            name: "period"
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let response = await this.$api.get("dictionaries/users");

        self.dropdownFilter("Assigned To").options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      users().then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      // if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },
    filterData: function(e) {
      //let self = this;

      this.filteringPanel.selected = e;
    },
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        accounts: f.account ? f.account.map(i => i.label) : [],
        assigned_to: f.assigned_to ? f.assigned_to.map(i => i.id) : [],
        period: f.period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      //this.$refs['accounts'].getData(payload)
      //this.$refs['invoices'].getData(this.$refs['filterPanel'].selected)
      this.$refs["invoices"].getData(payload);
    },
    dropdownFilter(_name) {
      return this.filteringPanel.filters.find(n => n.title === _name);
    },
    onInvoicesLoad() {}
  },
  watch: {}
};
</script>

<style></style>
